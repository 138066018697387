import './App.css';

function App() {
  return (
    <div className="App">
		<div>
			<h1>Great things are coming soon!</h1>
			<p>Please be patient...</p>
		</div>
    </div>
  );
}

export default App;
